// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as $$Date from "../../../../libs/Date.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserColocationRfpProject_Details_RequiredProductsScss from "./UserColocationRfpProject_Details_RequiredProducts.scss";
import * as UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription from "./UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserColocationRfpProject_Details_RequiredProductsScss;

function UserColocationRfpProject_Details_RequiredProducts_RackDetail(props) {
  var contractLength = props.contractLength;
  var startDate = props.startDate;
  var rackDetails = props.rackDetails;
  var q = rackDetails.quantity;
  var match = rackDetails.powerValue;
  var match$1 = rackDetails.powerUnits;
  var tmp;
  var exit = 0;
  if (match !== undefined && match$1 !== undefined) {
    tmp = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                label: "Power in " + match$1,
                value: String(match)
              }),
          className: css.propertyLeft
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx("div", {
          className: css.propertyLeft
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: "Space & Power Requirements #" + String(props.index + 1 | 0),
                      className: css.productName
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Quantity",
                                    value: q !== undefined ? String(q) : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Colocation Space",
                                    value: Belt_Option.getWithDefault(rackDetails.colocationSpace, "")
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Circuit Type",
                                    value: Belt_Option.getWithDefault(rackDetails.powerCircuit, "")
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchLight
                                  ])
                            }),
                        tmp,
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Start Date",
                                    value: startDate !== undefined ? $$Date.Naive.format(Caml_option.valFromOption(startDate), "dd MMM yyyy") : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Contract Length",
                                    value: contractLength !== undefined ? String(contractLength) + " months" : ""
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                              options: rackDetails.additionalOptions,
                              cssStart: "SwitchLight"
                            })
                      ],
                      className: css.propertiesContainer
                    })
              ]
            });
}

var make = UserColocationRfpProject_Details_RequiredProducts_RackDetail;

export {
  css ,
  make ,
}
/* css Not a pure module */
