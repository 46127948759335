// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Date from "../libs/Date.res.js";
import * as Project from "./Project.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as UserContact from "./UserContact.res.js";
import * as LocationForProject from "./LocationForProject.res.js";
import * as ProviderForProject from "./ProviderForProject.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as ColocationRfpProjectId from "./ColocationRfpProjectId.res.js";
import * as ColocationConfiguration from "./ColocationConfiguration.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ColocationRfpProjectId.decoder),
              title: field.required("title", Json_Decode$JsonCombinators.string),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              status: field.required("status", Project.Status.decoder),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              totalProviders: field.required("totalProviders", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              totalProposals: field.required("totalProposals", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.$$int)),
              startDate: field.required("startDate", $$Date.Naive.decoder),
              endDate: field.required("endDate", $$Date.Naive.decoder),
              isAgent: field.required("isAgent", Json_Decode$JsonCombinators.bool),
              businessName: field.required("businessName", Json_Decode$JsonCombinators.string),
              businessContactName: field.required("businessContactName", Json_Decode$JsonCombinators.string),
              headquartersAddress: field.required("headquartersAddress", Json_Decode$JsonCombinators.string),
              businessEmail: field.required("businessEmail", Json_Decode$JsonCombinators.string),
              businessPhone: field.required("businessPhone", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder),
              owner: field.required("owner", Project.Owner.decoder),
              contacts: field.required("contacts", Json_Decode$JsonCombinators.array(UserContact.decoder)),
              documents: field.required("documents", Json_Decode$JsonCombinators.array(Project.$$Document.decoder)),
              colocationConfigurations: field.required("colocationConfigurations", Json_Decode$JsonCombinators.array(ColocationConfiguration.decoder)),
              providers: field.required("providers", Json_Decode$JsonCombinators.array(ProviderForProject.decoder)),
              locations: field.required("locations", Json_Decode$JsonCombinators.array(LocationForProject.decoder))
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              label: field.required("label", Json_Decode$JsonCombinators.string),
              value: field.required("value", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var ContractLength = {
  decoder: decoder$1,
  fromJson: fromJson$1
};

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              contractLengths: field.required("contractLengths", Json_Decode$JsonCombinators.array(decoder$1)),
              colocationSpace: field.required("colocationSpace", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              powerCircuit: field.required("powerCircuit", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              powerUnits: field.required("powerUnits", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              additionalSpaceAndPower: field.required("additionalSpaceAndPower", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              interconnect: field.required("interconnect", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              bandwidths: field.required("bandwidths", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              providers: field.required("providers", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              additionalInternetConnectivity: field.required("additionalInternetConnectivity", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              crossConnectProviders: field.required("crossConnectProviders", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              additionalCloudDirectConnects: field.required("additionalCloudDirectConnects", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              cloudPlatforms: field.required("cloudPlatforms", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              certificationRequirements: field.required("certificationRequirements", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              dataCenterFeatures: field.required("dataCenterFeatures", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string))
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var ConfigurationOptions = {
  ContractLength: ContractLength,
  decoder: decoder$2,
  fromJson: fromJson$2
};

export {
  decoder ,
  fromJson ,
  ConfigurationOptions ,
}
/* decoder Not a pure module */
