// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as RackTypesAndPowerDensity from "../../../../models/RackTypesAndPowerDensity.res.js";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserColocationRfpProject_Details_RequiredProductsScss from "./UserColocationRfpProject_Details_RequiredProducts.scss";
import * as UserColocationRfpProject_Details_RequiredProducts_RackDetail from "./UserColocationRfpProject_Details_RequiredProducts_RackDetail.res.js";
import * as UserColocationRfpProject_Details_RequiredProducts_CrossConnectDetail from "./UserColocationRfpProject_Details_RequiredProducts_CrossConnectDetail.res.js";
import * as UserColocationRfpProject_Details_RequiredProducts_CloudDirectConnectDetail from "./UserColocationRfpProject_Details_RequiredProducts_CloudDirectConnectDetail.res.js";
import * as UserColocationRfpProject_Details_RequiredProducts_InternetConnectivityDetail from "./UserColocationRfpProject_Details_RequiredProducts_InternetConnectivityDetail.res.js";

var css = UserColocationRfpProject_Details_RequiredProductsScss;

function panelHeading(rackTypesAndPowerDensities) {
  var rackDetails = Js_array.joinWith(", ", Belt_Array.map(rackTypesAndPowerDensities, (function (rack) {
              return RackTypesAndPowerDensity.rackQuantityAndPowerDetailText(rack);
            })));
  return JsxRuntime.jsx("h4", {
              children: rackDetails,
              className: css.panelHeading
            });
}

function UserColocationRfpProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading(props) {
  var options = props.options;
  if (options.length !== 0) {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx("p", {
                        children: props.heading,
                        className: css.productName
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                              options: options
                            }),
                        className: css.checkboxes
                      })
                ],
                className: css.checkboxesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptionsWithHeading = {
  make: UserColocationRfpProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading
};

function UserColocationRfpProject_Details_RequiredProducts_ProductPanel(props) {
  var __isExpanded = props.isExpanded;
  var colocationSpaceConfiguration = props.colocationSpaceConfiguration;
  var isExpanded = __isExpanded !== undefined ? __isExpanded : false;
  var notes = colocationSpaceConfiguration.additionalNotesOrRequirements;
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.panel,
              heading: panelHeading(colocationSpaceConfiguration.rackTypesAndPowerDensities),
              isExpanded: isExpanded,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      Belt_Array.mapWithIndex(colocationSpaceConfiguration.rackTypesAndPowerDensities, (function (index, rackDetails) {
                              return JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_RackDetail.make, {
                                          index: index,
                                          rackDetails: rackDetails,
                                          startDate: colocationSpaceConfiguration.startDate,
                                          contractLength: colocationSpaceConfiguration.contractLength
                                        }, Belt_Option.getWithDefault(rackDetails.colocationSpace, "") + String(index));
                            })),
                      Belt_Array.mapWithIndex(colocationSpaceConfiguration.internetConnectivities, (function (index, internetConnectivity) {
                              var match = internetConnectivity.bandwidth;
                              var match$1 = internetConnectivity.interconnect;
                              var match$2 = internetConnectivity.provider;
                              var match$3 = Belt_Array.get(internetConnectivity.additionalOptions, 0);
                              if (match === undefined && match$1 === undefined && match$2 === undefined && match$3 === undefined) {
                                return null;
                              }
                              return JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_InternetConnectivityDetail.make, {
                                          index: index,
                                          internetConnectivity: internetConnectivity
                                        }, String(index) + "-connectivity");
                            })),
                      Belt_Array.mapWithIndex(colocationSpaceConfiguration.crossConnects, (function (index, crossConnect) {
                              var match = crossConnect.quantity;
                              var match$1 = crossConnect.interconnect;
                              var match$2 = crossConnect.provider;
                              if (match === undefined && match$1 === undefined && match$2 === undefined) {
                                return null;
                              }
                              return JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_CrossConnectDetail.make, {
                                          index: index,
                                          crossConnect: crossConnect
                                        }, String(index) + "-crossConnect");
                            })),
                      Belt_Array.mapWithIndex(colocationSpaceConfiguration.cloudDirectConnects, (function (index, cloudDirectConnect) {
                              var match = cloudDirectConnect.bandwidth;
                              var match$1 = cloudDirectConnect.interconnect;
                              var match$2 = cloudDirectConnect.cloudPlatform;
                              var match$3 = Belt_Array.get(cloudDirectConnect.additionalOptions, 0);
                              if (match === undefined && match$1 === undefined && match$2 === undefined && match$3 === undefined) {
                                return null;
                              }
                              return JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_CloudDirectConnectDetail.make, {
                                          index: index,
                                          cloudDirectConnect: cloudDirectConnect
                                        }, String(index) + "-cloudDirectConnects");
                            })),
                      JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading, {
                            heading: "Certification Requirements",
                            options: colocationSpaceConfiguration.certificationRequirements
                          }),
                      JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptionsWithHeading, {
                            heading: "Data Center Features",
                            options: colocationSpaceConfiguration.datacenterFeatures
                          }),
                      notes !== undefined ? JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: "Custom Note or Requirements",
                                      className: css.productName
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: notes,
                                      className: css.additionalNotes
                                    })
                              ],
                              className: css.notesContainer
                            }) : null
                    ],
                    className: css.panelBody
                  })
            });
}

var make = UserColocationRfpProject_Details_RequiredProducts_ProductPanel;

export {
  css ,
  panelHeading ,
  CheckmarkableOptionsWithHeading ,
  make ,
}
/* css Not a pure module */
