// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as GoogleMap from "../../../../bindings/GoogleMap.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as GoogleMapStyles from "../../../../styleguide/styles/GoogleMapStyles.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as GoogleMapWithBounds from "../../../../styleguide/components/GoogleMap/GoogleMapWithBounds.res.js";
import * as Global from "Client/common/global";
import * as Api from "@react-google-maps/api";
import * as UserColocationRfpProject_Details_LocationsScss from "./UserColocationRfpProject_Details_Locations.scss";

var css = UserColocationRfpProject_Details_LocationsScss;

function getBounds(locations) {
  return Belt_Array.map(locations, (function ($$location) {
                return GoogleMap.Coordinates.fromLocationForProject($$location);
              }));
}

function UserColocationRfpProject_Details_Locations_Map(props) {
  var serverSide = props.serverSide;
  var locations = props.locations;
  return JsxRuntime.jsx(Api.LoadScript, {
              googleMapsApiKey: Global.GoogleMapAPI,
              libraries: Global.GoogleMapLibraries,
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx(GoogleMapWithBounds.make, {
                          mapContainerClassName: css.mapElement,
                          style: GoogleMapStyles.standard,
                          options: {
                            mapTypeControl: false,
                            zoomControl: true,
                            zoomControlOptions: {
                              position: 5
                            },
                            streetViewControl: false,
                            fullscreenControl: false
                          },
                          bounds: getBounds(locations),
                          children: JsxRuntime.jsx(GoogleMap.MarkerClusterer.make, {
                                averageCenter: true,
                                enableRetinaIcons: true,
                                gridSize: 60,
                                minimumClusterSize: 3,
                                styles: GoogleMap.MarkerClusterer.Style.$$default,
                                children: (function (clusterer) {
                                    return Belt_Array.map(locations, (function ($$location) {
                                                  return JsxRuntime.jsx(GoogleMap.Marker.make, {
                                                              position: {
                                                                lat: $$location.latitude,
                                                                lng: $$location.longitude
                                                              },
                                                              icon: serverSide ? undefined : GoogleMap.Marker.Icon.$$default,
                                                              clusterer: Caml_option.some(clusterer)
                                                            }, ID.toString($$location.id));
                                                }));
                                  })
                              })
                        }),
                    className: css.mapContainer
                  })
            });
}

var make = UserColocationRfpProject_Details_Locations_Map;

export {
  css ,
  getBounds ,
  make ,
}
/* css Not a pure module */
