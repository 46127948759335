// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserColocationRfpProject_Details_RequiredProductsScss from "./UserColocationRfpProject_Details_RequiredProducts.scss";
import * as UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription from "./UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserColocationRfpProject_Details_RequiredProductsScss;

function UserColocationRfpProject_Details_RequiredProducts_InternetConnectivityDetail(props) {
  var internetConnectivity = props.internetConnectivity;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("p", {
                      children: "Bandwidth Requirement #" + String(props.index + 1 | 0),
                      className: css.productName
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Access Type",
                                    value: Belt_Option.getWithDefault(internetConnectivity.interconnect, "")
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.stayShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Bandwidth",
                                    value: Belt_Option.getWithDefault(internetConnectivity.bandwidth, "")
                                  }),
                              className: Cx.cx([
                                    css.propertyRight,
                                    css.switchShaded
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(UserColocationRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                    label: "Provider",
                                    value: Belt_Option.getWithDefault(internetConnectivity.provider, "")
                                  }),
                              className: Cx.cx([
                                    css.propertyLeft,
                                    css.switchLight
                                  ])
                            }),
                        JsxRuntime.jsx("div", {
                              className: css.propertyRight
                            }),
                        JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                              options: internetConnectivity.additionalOptions,
                              cssStart: "StayShaded"
                            })
                      ],
                      className: css.propertiesContainer
                    })
              ]
            });
}

var make = UserColocationRfpProject_Details_RequiredProducts_InternetConnectivityDetail;

export {
  css ,
  make ,
}
/* css Not a pure module */
